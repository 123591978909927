<template>
  <div>
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DualListBox
              :source="locations"
              :destination="selectedLocations"
              label="title"
              @onChangeList="onChangeList"
            />
            <div class="pt-4 px-4">
              <button
                v-if="!isGenerated"
                class="btn btn-primary btn-block font-weight-bold"
                @click="generate"
              >
                Generate
              </button>
              <button
                v-else
                class="btn btn-primary btn-block font-weight-bold"
                @click="resetAll"
              >
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            >
            </DatePicker>
            <button
              @click="last7Days"
              class="btn btn-light-primary btn-block font-weight-bold mt-2"
            >
              Last 7 days
            </button>
            <button
              @click="last30Days"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last 30 days
            </button>
            <button
              @click="thisMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              This month
            </button>
            <button
              @click="lastMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last month
            </button>
            <button
              @click="reset"
              class="btn btn-light-danger btn-block font-weight-bold mt-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <b-spinner
          v-if="showSpinner"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </div>
    </div>
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Token Drop Traffic</h3>
          </div>
          <div
            v-if="generatedPrizeDropResults.length > 0"
            class="card-body p-0"
          >
            <MonsterTransactionsTraffic
              v-if="isGenerated"
              :traffic="generatedPrizeDropResults"
              :selected="selectedDevices"
              :range="range"
            />
          </div>
          <div
            v-else-if="
              isGenerated && this.generatedPrizeDropResults.length === 0
            "
            class="row"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              height: 100%;
            "
          >
            <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  height: 100%;
                "
              >
                <p class="font-weight-bold" style="font-size: 24px">
                  No transactions.
                </p>
                <p style="font-size: 16px">
                  There are currently no transactions with token drop type.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div class="col-md-12" style="width: 100%; height: 100%">
        <TransactionsTraffic
          v-if="isGenerated"
          :traffic="generatedResults"
          :range="range"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div class="col-md-6" style="width: 100%; height: 100%">
        <WeekDaysTraffic
          v-if="isGenerated"
          :traffic="generatedResults"
          :range="range"
          style="width: 100%; height: 100%"
        />
      </div>
      <div class="col-md-6" style="width: 100%; height: 100%">
        <YearTraffic
          v-if="isGenerated"
          :traffic="generatedResults"
          :range="range"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div class="col-md-6" style="width: 100%; height: 100%">
        <WeekTraffic
          v-if="isGenerated"
          :traffic="generatedResults"
          :range="range"
          style="width: 100%; height: 100%"
        />
      </div>
      <div class="col-md-6" style="width: 100%; height: 100%">
        <MonthTraffic
          v-if="isGenerated"
          :traffic="generatedResults"
          :range="range"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div class="col-md-12" style="width: 100%; height: 100%">
        <HoursTraffic
          v-if="isGenerated"
          :traffic="generatedResults"
          :range="range"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
    <div v-if="isGenerated" class="row">
      <div
        v-if="
          isGenerated &&
          currentActiveModuleComponents?.reports.reportByDaysInRange
        "
        class="col-md-12"
        style="width: 100%; height: 100%"
      >
        <DaysTraffic
          v-if="isGenerated"
          :type="'location'"
          :traffic="generatedResults"
          :selected="selectedLocations"
          :range="range"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import format from 'date-fns/format'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import addMonths from 'date-fns/addMonths'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import DualListBox from '@/view/content/components/dual-list-box/DualListBox'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { dateFilter } from 'vue-date-fns'
import WeekDaysTraffic from '@/view/pages/token-report/_components/WeekDaysTraffic'
import DaysTraffic from '@/view/pages/token-report/_components/DaysTraffic'
import HoursTraffic from '@/view/pages/token-report/_components/HoursTraffic'
import WeekTraffic from '@/view/pages/token-report/_components/WeekTraffic'
import MonthTraffic from '@/view/pages/token-report/_components/MonthTraffic'
import YearTraffic from '@/view/pages/token-report/_components/YearTraffic'
import TransactionsTraffic from '@/view/pages/token-report/_components/TransactionsTraffic'
import MonsterTransactionsTraffic from '@/view/pages/token-report/_components/MonsterTransactionsTraffic'
import ApiService from '@/core/services/api.service'
import addDays from 'date-fns/addDays'

export default {
  name: 'LocationTokenReport',
  filters: {
    date: dateFilter,
  },
  components: {
    DatePicker,
    DualListBox,
    WeekDaysTraffic,
    DaysTraffic,
    HoursTraffic,
    WeekTraffic,
    MonthTraffic,
    YearTraffic,
    TransactionsTraffic,
    MonsterTransactionsTraffic,
  },
  data() {
    return {
      locations: [],
      selectedLocations: [],
      locationsDevices: [],
      generatedResults: [],
      generatedPrizeDropResults: [],
      totalItems: null,
      range: {
        start: add(new Date(), { days: -1 }).setHours(24, 0, 0),
        end: new Date().setHours(23, 59, 59),
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      showAlert: false,
      alertMsg: null,
      base: 0,
      tax: 0,
      total: 0,
      isGenerated: false,
      showSpinner: false,
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Location reports', route: '/token-report/location' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get('locations', '?pagination=false').then(({ data }) => {
        this.totalItems = data['hydra:member'].length
        this.locations = [...data['hydra:member']]

        this.isLoaded = true
      })
    }
    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get(
        'locations',
        `?company=${this.currentUserCompany}&pagination=false`
      ).then(({ data }) => {
        this.totalItems = data['hydra:member'].length
        this.locations = [...data['hydra:member']]

        this.isLoaded = true
      })
    }
  },
  methods: {
    async generate() {
      if (
        this.selectedLocations.length === 0 ||
        this.range.start === null ||
        this.range.end === null
      ) {
        this.alertMsg = 'Please select location/s & pick range'
        this.showAlert = true

        return
      }
      this.showSpinner = true
      let num = 0
      const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")
      this.selectedLocations.forEach((location) => {
        ApiService.get(
          'transactions',
          `?location=${location['@id']}&company=${this.currentUserCompany}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false&isToken=true`
        ).then(({ data }) => {
          ApiService.get(
            'devices',
            `?location.id=${location['@id']}&pagination=false`
          ).then((response) => {
            response.data['hydra:member'].forEach((device) => {
              if (device.isMonsterDevice === true) {
                this.locationsDevices.push(device)
                data['hydra:member'].forEach(
                  ({
                    id,
                    imei,
                    deviceMeta,
                    amount,
                    paymentType,
                    createdAt,
                    locationMeta,
                  }) => {
                    if (
                      paymentType !== 'token_drop' &&
                      amount >= 0 &&
                      this.locationsDevices.find(
                        (item) => item.id === deviceMeta.id
                      )
                    ) {
                      this.generatedResults.push({
                        imei: imei,
                        deviceName: deviceMeta.name,
                        location: locationMeta.title ? locationMeta.title : '',
                        lessor: locationMeta.hasOwnProperty('lessor')
                          ? locationMeta.lessor
                          : '',
                        address: locationMeta.address
                          ? locationMeta.address
                          : '',
                        tags: locationMeta.tags ? locationMeta.tags : [],
                        amount: amount,
                        paymentType: paymentType,
                        createdAt: format(
                          new Date(createdAt),
                          'dd.MM.yyyy @ HH:mm:ss'
                        ),
                      })
                    }

                    if (
                      paymentType === 'token_drop' &&
                      amount >= 0 &&
                      this.locationsDevices.find(
                        (item) => item.id === deviceMeta.id
                      )
                    ) {
                      this.generatedPrizeDropResults.push({
                        id: id,
                        imei: imei,
                        deviceName: deviceMeta.name,
                        location: locationMeta.title ? locationMeta.title : '',
                        lessor: locationMeta.hasOwnProperty('lessor')
                          ? locationMeta.lessor
                          : '',
                        address: locationMeta.address
                          ? locationMeta.address
                          : '',
                        tags: locationMeta.tags ? locationMeta.tags : [],
                        amount: amount,
                        paymentType: paymentType,
                        createdAt: format(
                          new Date(createdAt),
                          'dd.MM.yyyy @ HH:mm:ss'
                        ),
                      })
                    }
                  }
                )
              }
            })
            num++
            if (num === this.selectedLocations.length) {
              this.isGenerated = true
              this.showSpinner = false
            }
          })
        })
      })
    },
    last7Days() {
      this.$refs.picker.updateValue({ start: null, end: null })
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -7 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({ start: null, end: null })
      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -30 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })
      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 2,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    reset() {
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    resetAll() {
      this.generatedResults = []
      this.totalItems = null
      this.range.start = null
      this.range.end = null
      this.base = 0
      this.tax = 0
      this.total = 0
      this.isGenerated = false
      this.showSpinner = false
      this.isLoaded = false
    },
    onChangeList: function ({ source, destination }) {
      this.locations = source
      this.selectedLocations = destination
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
  },
}
</script>
